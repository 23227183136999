/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect} from 'react'
import { ToastContainer } from 'react-toastify';
import { notify_warning,swall_error} from '../../components/utils';
//import { notify_warning,swall_error,notify_valido} from '../../components/utils';
//|import Cookies from 'universal-cookie';
import logo_ab from '../../assets/img/logo/logo_distribuidora_alvarez_bohl_home.png';
import logo_ca from '../../assets/img/logo/logo_distribuidora_alvarez_bohl_home.png';
import '../../App.css';
import $ from 'jquery'
// import { PDFDownloadLink } from "@react-pdf/renderer"
// import PdfFinal from '../../components/pdf/PdfFinal';
import Swal from 'sweetalert2';
//import logo_ab from '../assets/img/logo/logo_distribuidora_alvarez_bohl_home.png';

const Catalogo = () => {
  //|const cookies = new Cookies();

  const apiCategoria = '/post-api-catalogoCategoriaHana'
  const apiListaProd = '/post-api-CatalogoListarProductos'
  const [txt_clleva, setTxt_clleva] = useState(0) //AUMENTA IN INPUT
  const [txt_fprec, setTxt_fprec] = useState(0) //AUMENTA EL PRECIO FINAL DE LOS ARTICULOS SELECCIONADOS
  const [arrSearcPr, setArrSearcPr] = useState('Categorias')
  const [arrSlcbtnC, setArrSlcbtnC] = useState(1)

  const [selectCate, setSelectCate] = useState([]) //PARA LISTAR LAS CATEGORIAS
  const [arrListaPr, setArrListaPr] = useState([]) //PARA LISTAR PRODUCTOS
  const [arrResulPr, setArrResulPr] = useState([]) //PARA LISTAR DEPENDIENDO LA CATEGORIA
  const [arrListCar, setArrListCar] = useState([]) //PARA LISTAR LOS PRODICTOS CON STOCK SELECIONADOS
  const [arrDatosUrl, setArrDatosUrl] = useState([]) //PARA LISTAR LOS PRODICTOS CON STOCK SELECIONADOS
  // const [txt_clleva, setTxt_clleva] = useState(0)
  // const [selectCate, setSelectCate] = useState([])
  // const [arrListaPr, setArrListaPr] = useState([])

  // const [arrSearcPr, setArrSearcPr] = useState('Todos')
  // const [arrResulPr, setArrResulPr] = useState([])
  //const [isConfirmed, setIsConfirmed] = useState(false); //PARA HACER LA CONSULTA DEL SWAL
  //const [isFixed, setIsFixed] = useState(false); // PARA EL MOVIMIENTO DE LAS CARD'S
  //const [disabled, setDisabled] = useState(false);// DESHABILITAR BOTONES 

  useEffect(() => {
    // setTimeout(() => {
    //   $("html .light-style").data("template", "horizontal-menu-template")
    // }, 1000);

    $("#template-customizer").addClass("d-none")
    var urlActual = window.location.href.split("/");
    var url_dxl = urlActual[3]
    var url0 = '/decifrar_link'
    const veh_body = { cod_url :url_dxl}
    const showDataRef = async () => {
      const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(veh_body) }
      const response = await fetch(url0,opPe);
      const datadtos = await response.json();
      //console.log(datadtos);
      setArrDatosUrl(datadtos)
    }
    showDataRef()
    const showData1 = async () => {
      const opPe = { method: "POST", headers: {'Content-Type': 'application/json'} }
      const response = await fetch(apiCategoria,opPe);
      const datadtos = await response.json();
      //console.log(datadtos);
      setSelectCate(datadtos)
    }
    showData1()
    const showDataListaPrecios = async () => {
      const opPe = { method: "POST", headers: {'Content-Type': 'application/json'} }
      const response = await fetch(apiListaProd,opPe);
      const datadtos = await response.json();
      //console.log(datadtos);
      setArrListaPr(datadtos)
    }
    showDataListaPrecios()

    // const handleScroll = () => {
    //   const scrollTop = document.documentElement.scrollTop;
    //   //setIsFixed(scrollTop > 0);
    // };

    // window.addEventListener('scroll', handleScroll);
    // return () => {
    //   window.removeEventListener('scroll', handleScroll);
    // };

  },[])

  // const cardStyle = {
  //   position: isFixed ? 'fixed' : 'static',
  //   top: '0',
  //   left: '0',
  //   right: '0',
  //   width: '100%',
  //   transition: 'top 0.3s',
  // };

  const changeTxt_value = (event, item) => {
    const nuevosDatos = [...arrListaPr];
    const elementIndex = nuevosDatos.findIndex((obj => obj.Codigo === item.Codigo));
    nuevosDatos[elementIndex].CantSelec = event.target.value;
    setArrListaPr(nuevosDatos);
    //console.log(arrListaPr);
  };

  const onClickCarrito = async ()=>{
    //Validacion si es que se han seleccionado datos anteriormente
    const nuevosDato = [...arrListaPr];
    let count2 = 0
    /* eslint-disable-next-line */
    nuevosDato?.map((item2) => { 
      count2 +=Math.round(item2.Fin)
    })
    if(count2===0){
      swall_error("ERROR","No hay ningun dato seleccionado")
    }else{  
      setArrSlcbtnC(2)
      $("#carr").addClass("d-none")
      $("#btn_categorias").addClass("d-none")
      $("#btn_retorno").removeClass("d-none")
      $("#btn_reS").removeClass("d-none")
      const ddtos = [...arrListaPr];
      const nuevosDatos = [...ddtos];
      const resultado123 = nuevosDatos.filter( item2 => item2.CantSelec !== 0 );
      //console.log("listar datos que tengan asignado cantidad ->",resultado123);
      setArrListCar(resultado123);
      window.scrollTo({ //para regresar a la parte superior de la página
        top: 0,
        behavior: 'smooth',
      });
    }
  }
  const onClickReturn= async ()=>{
    setArrSlcbtnC(1)
    $("#carr").removeClass("d-none")
    $("#btn_retorno").addClass("d-none")
    $("#btn_categorias").removeClass("d-none")
    $("#btn_reS").addClass("d-none")
    window.scrollTo({ //para regresar a la parte superior de la página
      top: 0,
      behavior: 'smooth',
    });
  }
   
  const fc_redirigir  = () =>{ //después de darle click para descargar PDF

    // var numeroDeEntradas = window.history.length;
    // console.log("numeroDeEntradas", numeroDeEntradas);
    // window.history.pushState(null, null, window.location.href);
    // window.history.replaceState(null, null, window.location.href);
    // document.cookie.split(";").forEach(function(c) {
    //   document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    // });
    // caches.keys().then((names) => {
    //   names.forEach((name) => {
    //     caches.delete(name);
    //   });
    // });
    setTimeout(() => {
      //window.open('https://web.whatsapp.com/send?phone='+arrDatosUrl[0]['from'], '_blank');
      //window.open('whatsapp://send?phone='+arrDatosUrl[0]['from'], '_blank');
      //window.open('whatsapp://send?phone='+arrDatosUrl[0]['from']);
      window.open('whatsapp://send?phone='+15550856641);

    }, 1000);
    setTimeout(() => { 
      window.location.href = 'https://www.alvarezbohl.pe'; 
      window.history.replaceState(null, '', 'https://www.alvarezbohl.pe');
    }, 2500);

    
    
  }

  // const cerrar = () =>{ //después de darle click para descargar PDF
  //   setTimeout(() => {
  //     notify_valido("Descarga exitosa")
  //   }, 1000);
  //  //  setTimeout(() => {
  //  //    window.location.href = 'https://abohl360.alvarezbohl.pe/'; // redirigir a la página principal de abohl
  //  //  }, 2500);
  // }

  const handleDownloadPDF = async() => {
    let count2 = 0
    const nuevosDato = [...arrListaPr];
    /* eslint-disable-next-line */
    nuevosDato?.map((item2) => {
      count2 +=Math.round(item2.Fin)
    })
    
    console.log("arrDatosUrl",arrDatosUrl);
    console.log(arrDatosUrl[0]['from']);
    const url_ven = '/post-data-ctg-venta'
    let body = { from: arrDatosUrl[0]['from'], phone: arrDatosUrl[0]['phone'], dni: arrDatosUrl[0]['dni'] };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(url_ven,opPe);
    const data = await response.json();
    console.log(data);


       
    const CardCode = arrDatosUrl[0]['dni'];
    //const CardName =  " Jordan Siancas";
    const currentDate = new Date();
    const day = currentDate.getDate();
    const DocDueDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    // const newData = {CardCode,CardName,DocDate};
    //const list= {CardCode,CardName,DocDate,ProductosSelec:arrListCar.map(item=>({
    const listProducSelec = {CardCode,DocDueDate,DocumentLines:arrListCar.map(item=>({
    //Codigo:item.Codigo, Descripcion:item.Descripcion, CantSelec:item.CantSelec,Precio:item.Fin
    ItemCode:item.Codigo, Quantity:item.CantSelec, TaxCode:"IGV", UnitPrice:item.Fin
    }))}
    console.log('listProducSelec', listProducSelec)

         //validacion si es que hay datos para proseguir
    if(count2===0){
      swall_error("ERROR","No hay ningún dato seleccionado")
    }else{  
      Swal.fire({
        title: 'ADVERTENCIA',
        text: '¿Desea confirmar pedido?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        customClass: {
          container: 'my-swal'
        }
      }).then(async(result) => {
        if (result.isConfirmed) {
          //setDisabled(true); // desactivar Botones
          //dejar solo el boton de descarga PDF
          $("#btn_carrito").addClass("d-none")
          $("#carr").addClass("d-none")
          $("#card_boton").addClass("d-none") 
          $("#btn_reS").addClass("d-none")
          $("#btn_retorno").addClass("d-none")

          const url_ven2 = '/post-api-OrderVenta'
          let body2 = { arrData: listProducSelec};
          const opPe2 = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body2) }
          const response2 = await fetch(url_ven2,opPe2);
          const data2 = await response2.json();
          console.log('log dentro de la funcion fc_enviarListaProductosSelecionados',data2);

          // const resulfunn =  await fc_enviarListaProductosSelecionados(listProducSelec)
          // console.log("resulva",resulfunn);
          

          //setIsConfirmed(true); //por esto puse este wal acá (al confirmar aviso)
          //setTimeout(() => { fc_redirigir() }, 1000);
          //Swal.fire({title:'PEDIDO HECHO!!!', text:'Ahora puede descargar su recibo', icon:'success',showConfirmButton:false, timer: 2000, customClass: {container: 'my-swal'}})
          // **Swal.fire({title:'PEDIDO HECHO!!!', text:'Se le enviara un detalladado a su WhatsApp, sera redirigido a nuestra pagina web, gracias.', icon:'success',showConfirmButton:false, timer: 2000, customClass: {container: 'my-swal'}})
          //setTimeout(() => { fc_redirigir() }, 2500);
          fc_redirigir()

          

        }
      });
       
    }
  }

  // const fc_enviarListaProductosSelecionados = async(arrData) => {
  //   const url_ven2 = '/post-api-OrderVenta'
  //   let body2 = { arrData: arrData};
  //   const opPe2 = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body2) }
  //   const response2 = await fetch(url_ven2,opPe2);
  //   const data2 = await response2.json();
  //   console.log('log dentro de la funcion fc_enviarListaProductosSelecionados',data2);
  // }
  

  //   const onClickPT= async ()=>{
  //   let count2 = 0
  //   const nuevosDato = [...arrListaPr];
  //   /* eslint-disable-next-line */
  //     nuevosDato?.map((item2) => {
  //     count2 +=Math.round(item2.Fin)
  //     })
  //   if(count2===0){
  //     swall_error("ERROR","No hay ningún dato seleccionado")
  //   }else{  
  //      swall_warning("ADVERTENCIA","¿Desea confirmar pedido?")
  //      console.log("arrDatosUrl",arrDatosUrl);
  //      console.log(arrDatosUrl[0]['from']);
  //      const url_ven = '/post-data-ctg-venta'
  //      let body = { from: arrDatosUrl[0]['from'], phone: arrDatosUrl[0]['phone'], dni: arrDatosUrl[0]['dni'] };
  //      const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  //      const response = await fetch(url_ven,opPe);
  //      const data = await response.json();
  //      console.log(data);
  //   }
  //  }
    
  const onClickSCategoria = (event, index) => {
    //alert(index)
    const val1 = (index === "0") ? "Categorias" : index.Name
    setArrSearcPr(val1)

    const ddtos = [...arrListaPr];
    //const nuevosDatos = [...arrListaPr];
    const nuevosDatos = [...ddtos];


    if (val1 === "0") {
      setArrListaPr(ddtos);
    } else {
      const resultado1 = nuevosDatos.filter( item2 => item2.SubCat.substr(0,3) === index.Code );
      //const resultado2 = [...resultado1];
      setArrResulPr(resultado1);

      window.scrollTo({ //para regresar a la parte superior de la página
        top: 0,
        behavior: 'smooth',
      });
    }

  }
 

  /** Buttones */
  const onClickAu = async (event, item) => {
    let count = 0
    let count2 = 0

    const nuevosDatos = [...arrListaPr];
    const elementIndex = nuevosDatos.findIndex((obj => obj.Codigo === item.Codigo));

    if(nuevosDatos[elementIndex].Stock<=0){
      notify_warning("Ya no hay Stock!")
      console.log("Stock llega a cero, ya no se puede agregar mas productos")
    } else if(nuevosDatos[elementIndex].Stock>=0){
      
      nuevosDatos[elementIndex].CantSelec = nuevosDatos[elementIndex].CantSelec+1;
      nuevosDatos[elementIndex].Stock     = nuevosDatos[elementIndex].Stock-1;
      
      var a1=Math.round(nuevosDatos[elementIndex].CostoReal);
      var a2=Math.round(nuevosDatos[elementIndex].CantSelec);
      var a3=(a1*a2).toFixed(2);
      
      nuevosDatos[elementIndex].Fin = a3;
      setArrListaPr(nuevosDatos);
     }
     
     // eslint-disable-next-line array-callback-return
    nuevosDatos?.map((item2) => {
      count +=item2.CantSelec
      count2 +=Math.round(item2.Fin)
      setTxt_clleva(count)
      setTxt_fprec(count2)
    })
    //hacer la suma total de precios
    // eslint-disable-next-line array-callback-return
    // nuevosDatos?.map((item3) => {
      
    //   count2 +=Math.round(item3.Fin)
    //   setTxt_fprec(count2)
    //   console.log(count2)
    // })
      
  }
  const onClickDe = async (event, item) => {
    let count = 0
    let count2=0

    const nuevosDatos = [...arrListaPr];
    const elementIndex = nuevosDatos.findIndex((obj => obj.Codigo === item.Codigo));
    if (nuevosDatos[elementIndex].CantSelec===0) {
      //nuevosDatos[index].CantSelec=0;
      //setArrListaPr(nuevosDatos);
      console.log("la cantidad llega a cero")
    }else if(nuevosDatos[elementIndex].CantSelec>=0){

      nuevosDatos[elementIndex].CantSelec = nuevosDatos[elementIndex].CantSelec-1;
      //setTxt_clleva()
      nuevosDatos[elementIndex].Stock=nuevosDatos[elementIndex].Stock+1;
      var a1=Math.round(nuevosDatos[elementIndex].CostoReal);
      var a2=Math.round(nuevosDatos[elementIndex].CantSelec);
      var a3=(a1*a2).toFixed(2);

      nuevosDatos[elementIndex].Fin =a3;  
      //cookies.set('CooSarrListaPr1', nuevosDatos[index], { path: '/' });
      //setArrListaLl(nuevosDatos[index])
      setArrListaPr(nuevosDatos);
    }
    
    // eslint-disable-next-line array-callback-return
    nuevosDatos?.map((item2) => {
      count +=item2.CantSelec
      count2 +=Math.round(item2.Fin)
      setTxt_fprec(count2)
      setTxt_clleva(count)
    })

     //hacer la resta total de precios
     // eslint-disable-next-line array-callback-return
    //  nuevosDatos?.map((item3) => {    
    //   count2 +=Math.round(item3.Fin)
    //   setTxt_fprec(count2)
    //   console.log(count2)
    // })
  }




  return (
    <> 
    <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
      <div className="layout-container">
        <nav className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme" id="layout-navbar" >
          <div className="container-xxl">
            <div className="navbar-nav-right d-flex align-items-center " id="navbar-collapse" >
  
              <div className="col-8 col-md-6 text-start" ><img src={logo_ab}  alt="google home" style={{"width":"110px"} } /></div>
              <div className="col-4 col-md-6 text-end">
                <div className="btn-group">
                  <button type="button" className="btn btn-sm btn-danger rounded-pill d-none" id="btn_retorno" alt="Regresar al Catalogo" onClick={onClickReturn} > <span className="bx bx-arrow-back"></span></button>
                  <button style={{ "width":"120px", "overflow": "hidden", "textOverflow": "ellipsis", }} type="button" className="btn btn-sm btn-danger rounded-pill dropdown-toggle hide-arrow" id="btn_categorias" data-bs-toggle="dropdown" aria-expanded="false">{arrSearcPr}<i className="bx bx-dots-vertical-rounded"></i></button>
                  {/* <ul className="dropdown-menu dropdown-menu-end"  style={{"height": "300px", "overflowY": "scroll"}} > */}
                  <ul className="dropdown-menu dropdown-menu-end " style={{"fontSize":"11px"}}  >
                    <li><button className="dropdown-item btn-sm fw-bold" onClick={ (event) => onClickSCategoria(event, "0")} >TODOS</button></li>
                      {                        
                        selectCate?.map((item,index) => {
                          return <li key={index}><button  className="dropdown-item btn-sm fw-bold " onClick={ (event) => onClickSCategoria(event, item)} >{item?.Name}</button></li>
                        })
                      }
                  </ul>
                </div>
              </div>
        
            </div>
          </div>
        </nav>
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">         
              <div className="d-none d-xl-block d-xxl-block py-4"></div>
              <div className="row ">
              
                {
                

                  (arrSlcbtnC === 1 )
                  ?
                    ( arrSearcPr === "0" ||  arrSearcPr === "" || arrSearcPr==="Categorias") 
                    ?
                      arrListaPr?.map((item,index) => {
                        var val_img = (item?.ImgNom) ? "/arcatalogo/"+item?.ImgNom : logo_ca;
                        return (
                          <div className="col-6 col-md-6 col-lg-2 col-xl-2 px-2 mb-2" key={index}>
                            <div className="card  h-100 py-2 align-items-center ">{/** bg-label-danger */}
                              {/* <img src="/arcatalogo/23693.jpg"  alt="google home" style={{"width":"120px"} } /> */}
                              <img src={val_img}  alt="Alvarez Bohl" style={{"width":"120px","height":"120px"} } />
                              <div className="card-body text-center py-1 mb-1" >
                                
                                <h6 className="card-title fw-bold text-black mb-0" style={{"fontSize":"11px","height":"50px"}}>{item?.Descripcion}</h6>

                                <span className="fw-bold text-success" style={{"fontSize":"10px"}}>Stock <b className="fw-bold text-black">{Math.trunc(Math.round(item?.Stock).toFixed(2))}</b> <span className="fw-bold text-success" style={{"fontSize":"10px"}}>UNIDAD</span></span> 
                                <div className="mb-1" style={{"fontSize":"11px"}}>
                                  <span className="fw-bold text-primary" >S/ </span>
                                  {/* <span className="text-primary" value={item?.CostoReal}>{(Math.round(item?.CostoReal).toFixed(2))}</span> */}
                                  {/* <span className="fw-bold text-primary" >{(Math.round(item?.CostoReal).toFixed(2))}</span> */}
                                  <span className="fw-bold text-primary" >{(Math.round(item?.CostoReal).toFixed(2))}</span>
                                  <span >|</span>
                                  <span className="fw-bold text-muted" >S/ {item?.Fin}</span>
                                </div>
                                <div className="input-group input-group-sm">
                                  <button className="btn btn-xs btn-secondary" type="button" id="button-addon1" onClick={(event) => onClickDe(event, item)}><i className='bx bx-minus'></i></button>
                                  <input type="text" className="form-control form-control-xs text-center border-0 fw-bold text-black" value={item?.CantSelec} onChange={(event) => changeTxt_value(event, item)} readOnly={true}/>
                                  <button className="btn btn-xs btn-danger" type="button" id="button-addon1" onClick={(event) => onClickAu(event, item)}><i className='bx bx-plus'></i></button>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        )
                      })
                    :
                      arrResulPr?.map((item,index) => {
                        var val_img = (item?.ImgNom) ? "/arcatalogo/"+item?.ImgNom : logo_ca;
                          return (
                            <div className="col-6 col-md-6 col-lg-2 col-xl-2 px-1 mb-2" key={index}>
                              <div className="card border border-danger h-100 py-2 align-items-center ">{/** bg-label-danger */}
                                <img src={val_img}  alt="Alvarez Bohl" style={{"width":"120px"} } />
                                <div className="card-body text-center py-1 mb-1" >
                                  
                                  <h6 className="card-title fw-bold text-black mb-0" style={{"fontSize":"11px","height":"50px"}}>{item?.Descripcion}</h6>

                                  <span className="fw-bold text-success" style={{"fontSize":"10px"}}>Stock <b className="fw-bold text-black">{Math.trunc(Math.round(item?.Stock).toFixed(2))}</b> <span className="fw-bold text-success" style={{"fontSize":"10px"}}>UNIDAD</span></span> 
                                  <div className="mb-1" style={{"fontSize":"11px"}}>
                                    <span className="fw-bold text-primary" >S/ </span>
                                    {/* <span className="text-primary" value={item?.CostoReal}>{(Math.round(item?.CostoReal).toFixed(2))}</span> */}
                                    <span className="fw-bold text-primary" >{(Math.round(item?.CostoReal).toFixed(2))}</span>
                                    <span >|</span>
                                    <span className="fw-bold text-muted" >S/ {item?.Fin}</span>
                                  </div>
                                  <div className="input-group input-group-sm">
                                    <button className="btn btn-xs btn-secondary" type="button" id="button-addon1" onClick={(event) => onClickDe(event, item)}><i className='bx bx-minus'></i></button>
                                    <input type="text" className="form-control form-control-xs text-center border-0 fw-bold text-black" value={item?.CantSelec} onChange={(event) => changeTxt_value(event, item)} readOnly={true}/>
                                    <button className="btn btn-xs btn-danger" type="button" id="button-addon1" onClick={(event) => onClickAu(event, item)}><i className='bx bx-plus'></i></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            )
                      })
                  :
                    arrListCar?.map((item,index) => {
                      var val_img = (item?.ImgNom) ? "/arcatalogo/"+item?.ImgNom : logo_ca;
                      return (
                        <div className="col-md-4 col-lg-4 mb-2 " key={index}>
                          <div className="card border border-success">
                            <div className="card-body">
                              <div className="d-flex gap-3 ">
                                <div className="flex-shrink-0">
                                  <img src={val_img}  alt="Alvarez Bohl" className="w-px-100" />
                                </div>
                                <div className="flex-grow-1 ">
                                  {/* <div className="row">
                                    <div className="col-md-12"> */}
                                      {/* <h6 className="me-3"><a href="javascript:void(0)" className="text-body ">{item?.Descripcion} - {item?.SubCat} -- {item?.Codigo}</a></h6> */}
                                      <h6 className="card-title fw-bold text-black mb-0" style={{"fontSize":"11px","height":"30px"}}>{item?.Descripcion}</h6>
                                      {/* <div className="text-muted mb-1 d-flex flex-wrap"> <span className="badge bg-label-success ">UNIDAD</span>&nbsp;<span className="badge bg-label-info">Stock  <b className="text-secondary">{Math.trunc(Math.round(item?.Stock).toFixed(2))}</b></span></div> */}
                                      <span className="fw-bold text-success" style={{"fontSize":"10px"}}>Stock <b className="fw-bold text-black">{Math.trunc(Math.round(item?.Stock).toFixed(2))}</b> <span className="fw-bold text-success" style={{"fontSize":"10px"}}>UNIDAD</span></span> 
                                      <div className="mb-1" style={{"fontSize":"11px"}}>
                                        <span className="fw-bold text-primary" >S/ </span>
                                        {/* <span className="text-primary" value={item?.CostoReal}>{(Math.round(item?.CostoReal).toFixed(2))}</span> */}
                                        <span className="fw-bold text-primary" >{(Math.round(item?.CostoReal).toFixed(2))}</span>
                                        <span >|</span>
                                        <span className="fw-bold text-muted" >S/ {item?.Fin}</span>
                                      </div>
                                      {/* <div className="read-only-ratings mb-2" data-rateyo-read-only="true"></div> */}
                                      <div className="row">
                                        <div className="col-8 col-md-8"> 
                                          <div className="input-group input-group-sm">
                                            <button className="btn btn-sm btn-secondary" type="button" id="button-addon1" onClick={(event) => onClickDe(event, item)}><i className='bx bx-minus'></i></button>
                                            <input type="text" className="form-control form-control-xs text-center border-0 fw-bold text-black" value={item?.CantSelec} onChange={(event) => changeTxt_value(event, item)} readOnly={true}/>
                                            <button className="btn btn-sm btn-danger" type="button" id="button-addon1" onClick={(event) => onClickAu(event, item)}><i className='bx bx-plus'></i></button>
                                          </div>
                                        </div>
                                        <div className="col-2 col-md-2 ">
                                          <button className="btn btn-sm btn-danger" type="button" id="button-addon2" ><i className='bx bxs-trash'></i></button>
                                        </div>
                                      </div>
                                    {/* </div> */}
                                    
                                    {/* <div className="col-md-4">
                                      <div className="text-md-end ">
                                        <div className="my-2 my-md-4 "><span className="text-primary">S/<span className="text-primary"  value={item?.CostoReal}>{(Math.round(item?.CostoReal).toFixed(2))}</span></span>|<span className="text-muted">S/ {item?.Fin}</span></div>
          
                                      </div>
                                    </div> */}
                                  {/* </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                      )
                    })

                  


                }
                  
                


              </div>
            </div>
            <div className="my-swal bg-danger mb-0 py-2 rounded-top fw-bold text-danger"   style={{position: 'fixed', bottom: "0.99%", right: 0, left:0}}>
                <div className='row'>
                  <div className='col-9 '>
                    <button type="button" id="carr" className="btn btn-white rounded-pill btn-md mx-2 fw-bold text-danger " onClick={onClickCarrito} style={{"fontSize":"12px"}}>
                        {/* <span className="tf-icons bx bx-money"></span>&nbsp;<span >S/{txt_fprec}</span> &nbsp;Termina tu pedido aqui */}
                        <span className="tf-icons bx bxs-cart-add"></span>&nbsp;<span >{txt_clleva}</span>&nbsp;Termina tu pedido aqui
                      </button>
                      <button type="button" className="btn btn-white rounded-pill btn-md mx-4 fw-bold text-danger d-none" id="btn_reS" onClick={handleDownloadPDF} >
                        <span className="tf-icons bx bxs-cart-add"></span>&nbsp;<span >{txt_clleva}</span>&nbsp;Realizar Pedido 
                      </button>
                  </div>
                  <div className='col-3 px-1 py-2'>
                    <strong className="text-white "><span className="tf-icons bx bx-money "></span>&nbsp;<span >S/{txt_fprec}</span></strong> 
                  </div>
                </div>
                  
                  {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {isConfirmed && (
                  <PDFDownloadLink  document={<PdfFinal arrListCar={arrListCar} txt_fprec={txt_fprec} />} fileName="Recibo.pdf">
                              <button type="button" className="btn btn-md btn-danger"  onClick={cerrar}>&nbsp;<i className="tf-icons bx bxs-file-pdf"></i>&nbsp; Descargar Recibo PDF</button>
                  </PDFDownloadLink> 
                  )} */}
            </div>
              
            
          </div>
        </div>
      </div>
    </div>
    <ToastContainer />
    </>
  )
}

export default Catalogo