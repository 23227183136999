import React, {useState, useEffect,useCallback} from 'react'
import Cookies from 'universal-cookie';
import AsyncSelect from 'react-select/async';
export const SelectImput = ({ id, value, onChange, disabled, width = 100}) => {
  const cookies = new Cookies();
  const dtUser = cookies.get('dtUser');
  //const dtgrve = dtUser.ls_gr_ven SucursalSeleccionado
  //const dtSucu = dtUser.sl_dt_suc[0].suc_nombre
  return (
    <select id={id} className="form-select form-select-sm" style={{width: `${width}px`}} value={value} onChange={onChange} disabled={disabled}>
      {/* <option value='9999' >-- Seleccione --</option> */}
        {
          dtUser.sl_dt_suc?.map((item,index) => {
            return <option value={item?.Code} key={index} >{item?.Name}</option>
          })
        }
    </select>
  )
}

export const SelectSucursal = ({ id, value, onChange, disabled}) => {
  const cookies = new Cookies();
  const dtUser = cookies.get('dtUser');
  //const dtgrve = dtUser.ls_gr_ven SucursalSeleccionado
  //const dtSucu = dtUser.sl_dt_suc[0].suc_nombre
  return (
    <select id={id} className="form-select form-select-sm" value={value} onChange={onChange} disabled={disabled}>
      {/* <option value='9999' >-- Seleccione --</option> */}
        {
          dtUser.sl_dt_suc?.map((item,index) => {
            return <option value={item?.Code} key={index} >{item?.Name}</option>
          })
        }
    </select>
  )
}

export const SelectTipo = ({ id, url, value, onChange, disabled}) => {
  const [select, setSelect] = useState([])
  useEffect(() => {
    const showData1 = async () => {
      const apig = url
      //const body = { txt_tipo: 'TE', };
      const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
      const response = await fetch(apig,opPe);
      const datadtos = await response.json();
      //console.log(datadtos);
      setSelect(datadtos)
    }
    showData1()
  },[url])

  return (
    <select id={id} className="form-select form-select-sm" value={value} onChange={onChange} disabled={disabled}>
        <option value='9999' >-- Seleccione --</option>
        {
          select?.map((item,index) => {
            return <option value={item?.Code} key={index} >{item?.Name}</option>
          })
        }
    </select>
  )
}


const styles = {
  control: (provided, state) => ({
    ...provided,
    background: `#fff`,
    borderColor: `#D2D5DC`,
    minHeight: `29px`,
    height: `29px`,
    boxShadow: state.isFocused ? null : null,
    border: '1px solid #D2D5DC',

  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: `#697a8d`,
  }),

  input: (provided, state) => ({
    ...provided,
    color: `#697a8d`,
    fontSize: `0.75rem`,
    
  }),
  indicatorSeparator: state => ({
    display: `none`,
    
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    
  }),
};

export const SelectSearch = (prosp) => {
  //const [select, setSelect] = useState([])
  const funLoad = useCallback( async (inputValue) => {
    const apig = prosp.url+'&vtxtConsult='+inputValue
    const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
    const response = await fetch(apig,opPe);
    const datadtos = await response.json();
    return datadtos;
  
  },[prosp.url])

  return (
    <AsyncSelect 
      styles={styles}
      placeholder="Busque por Cod./Desc."
      name="form-field-name"
      className=" from-control form-control-sm"  
      value={prosp.value}
      cacheOptions
      defaultOptions
      getOptionLabel={e => e.Code +' - '+ e.Name}
      getOptionValue={e => e.Code }
      loadOptions={funLoad}
      onChange={prosp.Change}
    />
  )
}
