import { useState } from "react";
import { SelectTipo } from '../components/Selects/SelectsPrincipales'
import { msalConfig } from "../Config"
import { ToastContainer } from 'react-toastify';
import { PublicClientApplication } from "@azure/msal-browser"
import { notify_valido,notify_error,notify_warning } from '../components/utils';
import logo_ab from '../assets/img/logo/logo_distribuidora_alvarez_bohl_home.png';
import Cookies from 'universal-cookie';
import "../assets/vendor/css/pages/page-auth.css";
//import '../App.css';

function Login(){
   
    const cookies = new Cookies();
    const [txt_user_cod, settxt_user_cod] = useState('');
    const changetxt_user_cod = (event) => { settxt_user_cod(event.target.value); };
    const [txt_user_pas, settxt_user_pas] = useState('');
    const changetxt_user_pas = (event) => { settxt_user_pas(event.target.value); };
    const [sl_t_emp, setSl_t_doc] = useState('');
    const changeSl_t_emp = (event) => { setSl_t_doc(event.target.value);}
    
    
    
    const api_url = '/api-login'
    const api_url_tdoc ='/api-empresas';




    const onClickLogin = async() => {
        if(sl_t_emp!=="") {
            let body = { txt_codu:txt_user_cod, txt_pass:txt_user_pas, vcodEmpresa: sl_t_emp }
            const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
            if (txt_user_cod!=="" && txt_user_pas!=="") {
                //console.log("resdatos",resdatos[0].tp_usu);
                try {
                    const response = await fetch(api_url,opPe);
                    const resdatos = await response.json();
                    //console.log(resdatos);
                    if (resdatos!=='0') {
                        sessionStorage.setItem("user_logueado",  resdatos.usu_nombre)
                        localStorage.setItem("var_ingreso","localStore prueba")
                        cookies.set('CookieSession', 'True', { path: '/' });
                        cookies.set('dtEmp_id', sl_t_emp, { path: '/' });
                        cookies.set('dtUser_id', resdatos.usu_id, { path: '/' });
                        cookies.set('dtUser_tpuid', resdatos.tpu_id, { path: '/' });
                        cookies.set('dtUser_tipoUsu', resdatos.tpu_descripcion, { path: '/' });
                        cookies.set('dtUser_nombre', resdatos.usu_nombre, { path: '/' });
                        cookies.set('dtUser_sexo', resdatos.usu_sexo, { path: '/' });
                        cookies.set('dtUser', resdatos, { path: '/' });
                        
                        notify_valido("Datos validos, va ser dirigido a ventana principal.")
                        setTimeout(() => {
                            //window.location.href = "/contenido"
                            window.location.href = resdatos.descMenu
                        }, 2000)
                        
                    } else { notify_error("Error!, datos no existen en nuestra base de datos o se encuentra deshabilitado.") }
                } catch (error) { notify_error("Error 505 (Internal Server Error), no hay conexion con servidor.") }

            }else{ notify_warning("Error datos vacios o verificar datos.") }
        }else{ notify_warning("Se requiere seleccionar empresa por favor.") }
    }

   


    const PCAPP = new PublicClientApplication(msalConfig);
    const [isAuthenticated, setIsAuthenticated] = useState();
    
    
    const onClickLoginMicrosoft = async() => {
        try {
            await PCAPP.loginPopup({ scopes: msalConfig.scopes, prompt: "select_account", })
            setIsAuthenticated(true)
        } catch (err) {
            console.log("err",err);
        }
    }

    
    const account = PCAPP.getAllAccounts();

    const f_rspLoginPopup = async() => {
        console.log(account)
        const accountS = account[0];
        let body = { txt_codu: accountS.username, txt_pass: '', vcodEmpresa: sl_t_emp}
        const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        try {
            // no se a selecciona una empresa
            if (!(sl_t_emp!=="" && sl_t_emp!=="9999")){
                notify_warning("Selecione empresa.")
                setIsAuthenticated(false)
                return
            }
            
            const response = await fetch(api_url,opPe);
            const resdatos = await response.json();
            // el logeo a fallado
            if (!(resdatos!=='0')){
                notify_error("Error!, datos no existen en nuestra base de datos o se encuentra deshabilitado.")
                localStorage.clear();
                sessionStorage.clear();
                setIsAuthenticated(false)
                return
            }
            
            sessionStorage.setItem("user_logueado",  resdatos.usu_nombre)
            localStorage.setItem("var_ingreso","localStore prueba")
            cookies.set('CookieSession', 'True', { path: '/' });
            cookies.set('dtEmp_id', sl_t_emp, { path: '/' });
            cookies.set('dtUser_id', resdatos.usu_id, { path: '/' });
            cookies.set('dtUser_tipoUsu', resdatos.tpu_descripcion, { path: '/' });
            cookies.set('dtUser_nombre', resdatos.usu_nombre, { path: '/' });
            cookies.set('dtUser_sexo', resdatos.usu_sexo, { path: '/' });
            cookies.set('dtUser', resdatos, { path: '/' });
            
            notify_valido("Datos validos, va ser dirigido a ventana principal.")
            setTimeout(() => {
                //window.location.href = "/contenido"
                // console.log(resdatos)
                window.location.href = resdatos.descMenu
            },1000)
        } catch (error) {
            notify_error("Error 505 (Internal Server Error), no hay conexion con servidor.")
            setIsAuthenticated(false)
        }
    }

    if (isAuthenticated) {
        f_rspLoginPopup();
    }

    return (
        <div id="idlogin" className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner">
                {/* Register */}
                <div className="card">
                    <div className="card-body">
                    {/* Logo */}
                    <div className="app-brand justify-content-center">
                        {/* <a href="/Home" className="app-brand-link gap-2"> */}
                        <span className="app-brand-logo demo">
                            <img src={logo_ab} alt="logo Alvarez Bohl" width={200}/>
                        </span>
                        {/*<span className="app-brand-text demo text-body fw-bolder " > Alvarez Bohl D</span>*/}
                        {/* </a> */}
                    </div>
                    {/* /Logo */}
                    <h4 className="mb-2">Bienvenido! 👋</h4>
                    
                    <p className="mb-4">Ingrese sus datos para iniciar sesion</p>
                     {/*<form id="formAuthentication" className="mb-3" action="index.html" method="POST">*/}
                        <div className="mb-3">
                            <label htmlFor="text" className="form-label">Empresa</label>
                            <div className="input-group input-group-merge">
                                <SelectTipo id="emp_slc_tdo" url={api_url_tdoc} value={sl_t_emp} onChange={changeSl_t_emp} />
                                <span className="input-group-text cursor-pointer"><i className="bx bx-store-alt" /></span>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="text" className="form-label">E-mail</label>
                            <div className="input-group input-group-merge">
                                <input type="email" className="form-control form-control-sm text-center" id="txt_user_cod" name="txt_user_cod" placeholder="ejemplo@alvarezbohl.pe" autoComplete="off" onChange={changetxt_user_cod} />
                                <span className="input-group-text cursor-pointer"><i className="bx bx-user" /></span>
                            </div>
                        </div>
                        <div className="mb-3 form-password-toggle">
                            <div className="d-flex justify-content-between">
                                <label className="form-label" htmlFor="password">Password</label>
                                {/* <a href="auth-forgot-password-basic.html">
                                <small>Forgot Password?</small>
                                </a>    */}
                            </div> 
                            <div className="input-group input-group-merge">
                                <input type="password" className="form-control form-control-sm text-center"  id="txt_user_pas" name="txt_user_pas" placeholder="············" aria-describedby="password" autoComplete="off" onChange={changetxt_user_pas} />
                                <span className="input-group-text cursor-pointer"><i className="bx bx-hide" /></span>
                            </div>
                        </div>
                        {/*<div className="mb-3">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="remember-me" />
                                <label className="form-check-label" htmlFor="remember-me"> Remember Me </label>
                            <button className="btn btn-primary d-grid w-100" id="btn_ing_micro" onClick={ onClickLoginMicrosoft }   >Microsoft</button>

                            </div>
                        </div>*/}
                        <div className="mb-3">
                            <button className="btn btn-youtube d-grid w-100" id="btn_ing_login" onClick={ onClickLogin } >Ingresar</button>
                        </div>
                        <div className="mb-3 ">
                            <button type="button" className="btn btn-label-twitter w-100" id="btn_ing_micro" onClick={ onClickLoginMicrosoft } > <i className="tf-icons bx bxl-microsoft"></i> Microsoft </button>
                        </div>
                     {/*</form>
                   <p className="text-center">
                        <span>New on our platform?</span>
                        <a href="auth-register-basic.html">
                        <span>Create an account</span>
                        </a>
                    </p>*/}
                    </div>
                </div>
                {/* /Register */}
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
export default Login

/**
 * <Link to="/home"></Link>
 */