import React, { useState, useMemo, useEffect } from 'react'

import '../../App.css';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { AButton } from '../../components/Button';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'react-toastify';
import { notify_warning, notify_valido } from '../../components/utils';
import { Box, Modal, Tooltip } from '@mui/material';
import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';
import moment from 'moment';

const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');

const Maestro = () => {

  const [listaDatos, setListaDatos] = useState([])
  const [listaDatosEnvio, setListaDatosEnvio] = useState([])
  const [sorting, setSorting] = useState([]);
  const [pending, setpending] = useState(false);
  const [inp_text_fecha, setInp_text_fecha] = useState(new Date());
  const fc_cargar_excel = async (event) => {
    setpending(true)
    const ideProveedor = "P&G"
    const timestamp = Date.now(); 
    const formData = new FormData();
    formData.append('xml', (event.target.files[0]));
    formData.append('ideProveedor', ideProveedor);
    formData.append('codEmpresa', cook_idEmp);
    const options = { method: "POST", headers: { 'Cache-Control': 'no-cache' }, body: formData }
    try {
      const response = await fetch('/apiSubirListarDatosExcel?t='+timestamp, options);

      if (!response.ok) {
        throw new Error('Error al cargar los datos desde la API');
      }

      const resdata = await response.json();
      console.log("API Data: ", resdata);

      // Verificar si la respuesta contiene los datos esperados
      if (resdata && resdata.jsonData) {
        setListaDatos(resdata.jsonData);
        setListaDatosEnvio([resdata.enviados]);

      }
      // error en caso el archivo no este correcto
      if (resdata.errorGen) {
        notify_warning("" + resdata.messaje)
      }

      if (resdata.errorIdplan) {
        Swal.fire({
          title: 'Error',
          text: ''+resdata.messaje,
          icon: 'error',
          confirmButtonText: 'Cerrar',
          customClass: {
            popup: 'swal2-medium',
            icon: 'swal2-large',
            title: 'swal2-large',
          }
        });
        
        
        // notify_warning("" + resdata.messaje)
      }
      // else {
      //   throw new Error('La respuesta de la API no contiene los datos esperados');
      // }
    } catch (error) {
      // console.log("estoy en el cahthddjdj");
      notify_warning("Error al registrar, verificar datos!")
    } finally {
      setpending(false);
    }
    // const responsea = await fetch('/apiSubirListarDatosExcel', options);
    // const data = await responsea.json()
    // console.log("APi:: ",data);
    // //const tableData = { columns, data };
    // setListaDatos(data.jsonData)
    // setListaDatosEnvio([data.enviados]);

    // //console.log(tableData.data);
    // setpending(false)
  }


  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "RB-AlvarezBohl"

  }

  const lstSincronizado = useMemo(() => [
    'Sincronizado',
    'No Sincronizado',
    'Error',
    'IDPlan Duplicado',
    'Sincronizado parcial'
  ], []);

  const lstTipoDeBonificacion = useMemo(() => [
    'T0: Descuento a precio',
    'T1: Bonificación Simple',
    'T2: Bonificación por Soles',
    'T3: Combitos',
    'T4: Combazos',
    'T6: Escala',
  ], []);

  function impriBaseDa(){
    console.log(" la base de ddtos es : ", cook_idEmp );
  }
  useEffect( ()=>{
    function handleKeyDown(event) {
      // Verificar si se presionó la tecla Escape (código 27)
      if (event.keyCode === 27) {
        console.log('Se presionó la tecla Escape');
        setModalOpen(false);
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  //*** funciones del modal  */

  const [selectedRow, setSelectedRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleRowMouseDown = (event, row) => {
    console.log(row);
    setSelectedRow(row.original.arrayDetalleDeInserciones);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };


  /** fin de funciones de mdoal  */


  const columns = useMemo(() => [

    {
      header: 'Periodo', accessorKey: 'AF',
      Cell: ({ row }) => {
        const formattedDate = moment(row.original.AF, 'DD-MM-YYYY').format('MM-YYYY');
        return <span>{formattedDate}</span>;
      }
    },
    { header: 'CODIGO DE PLAN', accessorKey: 'B', size: 200 },
    { header: 'ID DE PLAN', accessorKey: 'C' },
    {
      header: 'Estado de regla', accessorKey: 'ZZ', filterVariant: 'multi-select', filterSelectOptions: lstSincronizado,
      muiTableBodyCellProps: ({ row, cell }) => {
        const valor = row.original.ZZ
        let backgroundColor = "";
        if (valor === "Sincronizado") {
          backgroundColor = '#91F77A'
        } else if (valor === "Error") {
          backgroundColor = '#E91200'
        } else {
          backgroundColor = '#F7921E'
        }
        return {
          sx: { backgroundColor }
        }
      }
      // [
      //   "Sincronizado",
      //   "No Sincronizado"
      //   // { text: 'Ocurrió un error', value: 'Ocurrió un error' },
      // ],

    },
    // {header: 'IO', accessorKey: 'D' },
    // {header: '', accessorKey: 'E' },
    { header: 'CATEGORIA', accessorKey: 'F' },
    { header: 'MARCA', accessorKey: 'G' },
    { header: 'BODEGAS', accessorKey: 'H', enableColumnFilter: false, size: 140, },
    { header: 'MERCADOS', accessorKey: 'I', enableColumnFilter: false, size: 150, },
    { header: 'MAYORISTAS', accessorKey: 'J', enableColumnFilter: false, size: 160, },
   

    {
      header: 'TIPO PLAN', accessorKey: 'K', filterVariant: 'multi-select', filterSelectOptions:
        lstTipoDeBonificacion
    },
    // producto A
    {header: 'PRODUCTO_A', accessorKey: 'L' },
    {header: 'EAN_A', accessorKey: 'M' },
    // {header: '', accessorKey: 'N' },
    {header: 'CANTIDAD_A', accessorKey: 'O' },
    {header: 'UNIDAD MEDIDA_A', accessorKey: 'P' },
    // producto B
    {header: 'CANTIDAD_B', accessorKey: 'Q' },
    {header: 'UNIDAD MEDIDA_B', accessorKey: 'R' },
    { header: 'EAN BONIFICADO_B', accessorKey: 'S', size: 200, },
    { header: 'PRODUCTO_B', accessorKey: 'T' },
    // producto C 
    { header: 'CANTIDAD_C', accessorKey: 'U' },
    { header: 'UNIDAD DE MEDIDA_C', accessorKey: 'V', size: 200, },
    { header: 'EAN MODIFICADO_C', accessorKey: 'W', size: 200, },
    { header: 'PRODUCTO_C', accessorKey: 'X' },
    // producto Bonificado 
    { header: 'CANTIDAD_D', accessorKey: 'Y' },
    { header: 'UNIDAD DE MEDIDA_D', accessorKey: 'Z', size: 200, },
    { header: 'EAN BONIFICADO_D', accessorKey: 'AA', size: 200, },
    { header: 'PRODUCTO_D', accessorKey: 'AB' },
    // detalles
    { header: '% Dsct.', accessorKey: 'AC' },
    { header: 'Max x POS?', accessorKey: 'AD' },
    { header: 'Comentario', accessorKey: 'AE' },
    { header: 'FECHA INICIO', accessorKey: 'AF' },
    { header: 'FECHA FIN', accessorKey: 'AG', filterFn: 'equals', },
    { header: 'Ven Incluido', accessorKey: 'AH', filterFn: 'equals', },
    { header: 'Ven Excluido', accessorKey: 'AI', filterFn: 'equals', },
    { header: 'Sucursal', accessorKey: 'AJ' },
    // {header: 'Costo de la promoción', accessorKey: 'AH' },
    // {header: '¿Esta promo está alineada con los RBU promo guidelines?', accessorKey: 'AI' },
    // {header: 'Si la respuesta es no, ¿hay aprobación del RBU?', accessorKey: 'AJ',},

  ], [lstSincronizado, lstTipoDeBonificacion],);

  async function   listarHistorico(){
    setpending(true)
    const periodo = moment(inp_text_fecha, 'DD/MM/YYYY').format('YYYY-MM')
    console.log("perido: ", periodo);
    // formData.append('codEmpresa', cook_idEmp);
    const options = { method: "POST", headers: {  'Content-Type': 'application/json'}, body: JSON.stringify({periodo: periodo}) }
    try {
      const response = await fetch('/lisHistorico?periodo='+periodo, options);
      // console.log("API Data: ", response);
      if (!response.ok) {
        throw new Error('Error al cargar los datos desde la API');
      }

      const resdata = await response.json();
      console.log("API Data: ", resdata);

      // Verificar si la respuesta contiene los datos esperados
      if (resdata.estado === 1) {
        setListaDatos(resdata.data);
      }else if (resdata.estado === 2) {
        setListaDatos([]);
        notify_warning("No se  encontraron datos !")
      }else{
        setListaDatos([]);
        notify_warning("Error en la consulta verificar datos!")// Manejo de errores del servidor
        return;
      }
      // if (resdata.errorGen) {
      //   notify_warning("" + resdata.messaje)
      // }

      // if (resdata.errorIdplan) {
      //   Swal.fire({
      //     title: 'Error',
      //     text: ''+resdata.messaje,
      //     icon: 'error',
      //     confirmButtonText: 'Cerrar',
      //     customClass: {
      //       popup: 'swal2-medium',
      //       icon: 'swal2-large',
      //       title: 'swal2-large',
      //     }
      //   });
      // }
        
        
        // notify_warning("" + resdata.messaje)
      // else {
      //   throw new Error('La respuesta de la API no contiene los datos esperados');
      // }
    } catch (error) {
      setListaDatos([]);
      // console.log("estoy en el cahthddjdj");
      notify_warning("Error en la consulta verificar datos!")
    } finally {
      setpending(false);
    }
  }
  const estiloTabla = {
    borderRadius: '10px', // Ajusta el valor según el grado de redondeo que desees
    // Agrega cualquier otro estilo necesario
  };
  return (
    <div>

      <h6 className="text-muted">RB - AlvarezBohl-1</h6>
      {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalToggle1">Abrir Modal</button> */}
      <div className="card ">
        <div className="card-header ">
          {/* <button type="button" className="btn btn-success d-none" onClick={Guardar}> */}
          {/* <i className="tf-icons bx bxs-cloud-download" />
          </button>  */}
          <div className="row">
            <div className="col-lg-2">
              <label htmlFor="formFile" className="btn btn-danger btn-sm">
                <i className="tf-icons bx bx-upload" /> Subir Documento
              </label>
              <input type="file" id="formFile" name="formFile" accept='.csv,.xlsx,.xlsm' className="form-control form-control-sm d-none" onChange={fc_cargar_excel} />
            </div>

            <div className="col-lg-1">
              Periodo:
            </div>
            <div className="col-lg-2" style={{ zIndex: '3' }}>
              <DatePicker
                id="fecha"
                locale="es"
                className="form-control form-control-sm text-center"
                selected={inp_text_fecha}
                onChange={date => setInp_text_fecha(date)}
                dateFormat="MM/yyyy"  // Muestra solo el mes y el año
                showMonthYearPicker  // Permite seleccionar mes y año directamente
              />
            </div>

            <div className="col-lg-2">
              <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={listarHistorico} >
                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                &nbsp;Consultar
              </button>
            </div>

            <div className="col-lg-4">
              <div className="row justify-content-end">
                <div className="col-auto">
                  <button type="button" className="btn btn-success btn-sm" onClick={onClickExcel}>
                    <i className="tf-icons bx bxs-cloud-download" /> Descargar Reporte
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>


        {/* <a href={"/archivos/image.pmg"} target="_blank" rel="noreferrer">holaaa</a> */}


        <div className="card-body">  {/* <div className="card-body">  */}
          <MaterialReactTable
            columns={columns}
            data={listaDatos}
            className={estiloTabla}
            enableColumnResizing
            enableGrouping
            enableStickyHeader
            onSortingChange={setSorting}
            state={{ isLoading: pending, sorting }}
            initialState={{ showColumnFilters: false, density: 'compact', expanded: true, showGlobalFilter: false }} //filtro de columnas y toggle pequeño
            enableStickyFooter
            muiToolbarAlertBannerChipProps={{ color: 'primary' }}
            enablePagination={true}
            enableColumnActions={false}
            localization={MRT_Localization_ES}
            muiTableContainerProps={{ sx: { maxHeight: 450 } }}
            muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
            enableGlobalFilterModes
            rowVirtualizerProps={{ overscan: 8 }}
            enableRowVirtualization
            enableRowActions
            renderRowActions={({ row }) => (
              <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                {/* bt_funct={() => onClickModalDetalle(row)} */}
                <AButton tp_tilde="Mas detalle" tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-success detallePedido" bt_funct={(event) => handleRowMouseDown(event, row)} ic_class="bx bx-ms bx-show" vl_tpbtn={1} />

              </Box>
            )}
            // muiTableBodyRowProps={({ row, event }) => ({
            //   onMouseDown: (event) => handleRowMouseDown(event, row)
            // })}
          />
        </div>
        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasRightLabel">Envios</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <div className='d-flex flex-column'>
              <h6 className="offcanvas-title" id="offcanvasRightLabel">T0: Descuentos de precios</h6>
              <div className='d-flex mr-4'>
                <div><span className="badge bg-secondary">{listaDatosEnvio[0]?.descuentoPrecio?.sincronizados?.length}</span> Envios correctos </div>
                {/* <button className='btn btn-sm btn-primary'>Detalles</button> */}
                <span>
                </span>
                <AButton tp_tilde="Probar2" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-danger d-none" ic_class="bx bx-ms bx-dots-horizontal-rounded" vl_tpbtn={1} />
              </div>
              <div className='d-flex'>
                <p><span className="badge bg-secondary">{listaDatosEnvio[0]?.descuentoPrecio.error.length}</span> Envios fallidos </p>
              </div>
            </div>
            <div className='d-flex flex-column'>
              <h6 className="offcanvas-title" id="offcanvasRightLabel">T1: Bonificacion simple</h6>
              <div className='d-flex mr-4'>
                <div><span className="badge bg-secondary">{listaDatosEnvio[0]?.bonificacionSimple?.sincronizados?.length}</span> Envios correctos</div>
              </div>
              <div className='d-flex'>
                <p><span className="badge bg-secondary">{listaDatosEnvio[0]?.bonificacionSimple.error.length}</span> Envios fallidos</p>
              </div>
            </div>
            <div className='d-flex flex-column'>
              <h6 className="offcanvas-title" id="offcanvasRightLabel">T2: Bonificacion por soles</h6>
              <div className='d-flex mr-4'>
                <div><span className="badge bg-secondary">{listaDatosEnvio[0]?.bonificacionSoles?.sincronizados?.length}</span> Envios correctos</div>
              </div>
              <div className='d-flex'>
                <p><span className="badge bg-secondary">{listaDatosEnvio[0]?.bonificacionSoles.error.length}</span> Envios fallidos</p>
              </div>
            </div>
            <div className='d-flex flex-column'>
              <h6 className="offcanvas-title" id="offcanvasRightLabel">T6: Escala</h6>
              <div className='d-flex mr-4'>
                <div><span className="badge bg-secondary">{listaDatosEnvio[0]?.escala?.sincronizados?.length}</span> Envios correctos</div>
              </div>
              <div className='d-flex'>
                <p><span className="badge bg-secondary">{listaDatosEnvio[0]?.escala.error.length}</span> Envios fallidos</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

      <ModalDetalleFila
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        selectedRow={selectedRow}
      />

    </div>

  )
}

export default Maestro

function ModalDetalleFila({ modalOpen, handleCloseModal, selectedRow }) {
  return (
    <div className={` modal fade  ${modalOpen ? 'show' : ''}`} id="modalToggle1" tabIndex="-1" style={{ display: modalOpen ? 'block' : 'none' }}>
      <div className="modal-dialog modal-lg ">
        <div className="modal-content  ">
          <div className="modal-header">
            <h5 className="modal-title ">Detalle de sincronización</h5>
            <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {selectedRow  ? (
              <div>
                <div className="row mb-1">
                  <div className="col mb-0">
                    <label className="form-label">ID PLAN:</label>
                    <span className="form-control" aria-readonly="true">{selectedRow.idPlan}</span>
                  </div>
                  <div className="col mb-0">
                    <label className="form-label">ESTADO:</label>
                    <span className="form-control" aria-readonly="true">{selectedRow.estado}</span>
                  </div>
                </div>
                <label className="form-label">MENSAJE:</label>
                {/* <div className="form-control" aria-readonly="true" style={{ whiteSpace: 'pre-wrap' }}> */}
                <div className="form-control" aria-readonly="true" style={{ whiteSpace: 'pre-wrap', overflowY: 'auto', maxHeight: '370px' }}>
                  {/* <pre className="text-primary">{selectedRow.mensaje}</pre> */}
                  {selectedRow.mensaje.split('\r').map((line, index) => {
                    let modifiedLine = line;
                    if (line.includes("**")) {
                      modifiedLine = line.replace(/\*\*/g, '🟢'); // Reemplazar ** por círculo verde
                    } else if (line.includes("##")) {
                      modifiedLine = line.replace(/##/g, '🟠'); // Reemplazar ## por círculo rojo
                    }
                    return (
                      <p style={{ marginBottom: '0px', fontSize: '13px' }} key={`${line}_${index}`}>
                        {modifiedLine}
                      </p>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div>
                <p className="text-primary">El IDPlan de la fila no está definido</p>
              </div>
            )}

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Cerrar</button>
            {/* Agrega aquí los botones u opciones adicionales del pie del modal si es necesario */}
          </div>
        </div>
      </div>
    </div>
  );
}
