
import * as React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom"
import { registerLocale } from "react-datepicker";
import { useMemo } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import es from 'date-fns/locale/es';
//import { useIsAuthenticated } from "@azure/msal-react";
import Cookies from 'universal-cookie';
/** Vistas publicas - blancas */
import Login from "./views/Login"
import Catalogo from "./views/Public/Catalogo";

import Dashboard from "./views/Dashboard";
import Home from "./views/Home";

import Contenido from "./views/Contenido";
// import Trabajadores from "./views/Mantenimiento/Trabajadores";
// import TablasGenerales from "./views/Mantenimiento/TablasGenerales";
// import Usuarios from "./views/Mantenimiento/Usuarios";
import Error404 from "./views/error/error_404";
import Error503 from "./views/error/error_503";

/** REPARTO */
import REPMantenimiento from './views/Reparto/REPMantenimiento';
// import REPPreConsolidadoDeCarga from './views/Reparto/REPPreConsolidadoDeCarga';
// import REPVisitasRepartidor from './views/Reparto/REPVisitasRepartidor';
// import REPVisitasVendedor from './views/Reparto/REPVisitasVendedor';
const globalTheme = window.templateCustomizer.settings.style;


registerLocale('es', es)

function App() {


  const cookies = new Cookies();
  let status = cookies.get("CookieSession")
  var ultimaPagina = cookies.get("ultimapag")

  var urlActual = window.location.href.split("/");
  //var url_dxl = fc_unsubscribe(urlActual[3]).split("?");/** 3 - 4 */
  //console.log("url_dxl",url_dxl);
  var url_nam = urlActual[3];

  // const script = document.createElement('script');
  //   script.src = process.env.PUBLIC_URL + '/assets/vendor/libs/jquery/jquery_ANT.js';
  //   script.async = true;
  //   document.body.appendChild(script);

  // script.onload = () => {
  //   const analyticsScript = document.createElement('script');
  //   analyticsScript.src = process.env.PUBLIC_URL + '/assets/js/dashboards-analytics.js';
  //   analyticsScript.async = true;
  //   document.body.appendChild(analyticsScript);
  // };



  //status === 'False' || !status ?

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme,
          background: {
            default:
              globalTheme === 'light'
                ? 'rgb(254,255,244)'
                : '#2B2C40',
          },
        },
        typography: {
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
      }),
    [],
  );
  return (
    <ThemeProvider theme={tableTheme}>
      <Router>
        <Routes>
          {
            status === 'False' || !status ?
              //<Route path="/" element={<Login />} >
              <Route path="/" >
                <Route index element={<Login />} />
                <Route path="/login" element={<Login />} />
                {/* <Route path={`/${btoa("catalogo")}`} element={<Catalogo />} /> */}
                {/* <Route path={"/"+url_nam} element={<Catalogo />} /> */}
                {
                  url_nam.length > 20 ?
                    <Route path={"/" + urlActual[3]} element={<Catalogo />} />
                    :
                    <Route path="*" element={<Login />} />

                }

                <Route path="*" element={<Login />} />
              </Route>
              :
              <Route element={<Dashboard />}>
                <Route path="/contenido" exec={true} element={<Contenido />} />
                <Route path="/home" element={<Home />} />
{/* 
                <Route path="/trabajadores" element={<Trabajadores />} />
                <Route path="/tablasgenerales" element={<TablasGenerales />} />
                <Route path="/usuarios" element={<Usuarios />} /> */}

                <Route path="/repmantenimiento" element={<REPMantenimiento />} />
                {/* <Route path="/reppreconsolidadodecarga" element={<REPPreConsolidadoDeCarga />} />
                <Route path="/repvisitasrepartidor" element={<REPVisitasRepartidor />} />
                <Route path="/repvisitasvendedor" element={<REPVisitasVendedor />} /> */}


                <Route path="/*" element={ultimaPagina !== "" ? <Navigate to={"/" + ultimaPagina} /> : <Error503 />} />
              </Route>
          }
          <Route path="*" element={<Error404 />} ></Route>
        </Routes>
      </Router>
    </ThemeProvider>
  )
}
export default App